/* eslint-disable @typescript-eslint/no-explicit-any */
/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from "react";
import {
    Addon,
    AddonGroup,
    IProduct,
    IStore,
    ScreenState,
} from "@snackpass/snackpass-types";
import { SystemColors } from "@snackpass/design-system";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer, Dropdown, Menu, Space, Spin, Tooltip } from "antd";
import { CellInfo } from "react-table";
import fp, { compose } from "lodash/fp";
import { css } from "@emotion/react";
import { isDesktop, isMobile } from "react-device-detect";
import { DashOutlined } from "@ant-design/icons";

import useWindowDimensions from "#hooks/use-window-dimensions";
import {
    updateLegacyProduct,
    setActiveStore,
    setIsLoading as setGlobalIsLoading,
    setLegacyProducts,
    setActiveMenu,
    ActiveMenu,
} from "src/redux/slices";
import {
    getActiveProductCategories,
    getActiveProductCategory,
    getActiveStore,
    getActiveStoreIs3PIntegrated,
    getLegacyProducts,
    selectActiveMenu,
} from "src/redux/selectors";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import { ReactComponent as ChevronDown } from "src/assets/icons/chevron-down.svg";
import "antd/dist/antd.css";
import { styledWrapperCSS } from "#css";
import MenuAutomation from "#menu-editor/mobile-friendly/menu-automation";
import api from "src/api/rest";
import { fetchInventoryItems } from "src/redux/thunks";
import DropDownSelect from "#reusable/select/dropdown";
import Constants from "#core/constants";
import DuplicateMenu from "#menu-editor/mobile-friendly/items/duplicate-menu";
import {
    IProductCategoryWithProducts,
    MenuTopLevelContext,
} from "#menu-editor/mobile-friendly/helpers/context";
import {
    alertAxiosError,
    toLocaleFixed,
} from "#menu-editor/mobile-friendly/helpers/utils";
import { TrackProductFieldsModal } from "#menu-editor/multi-menus/shared-components/track-product-fields-modal";
import { getStoreMultiMenus } from "#menu-editor/multi-menus/redux/selectors";
import { useMultiMenusEnabled } from "#navigation/utils";
import { sendError, logAndSendError } from "src/utils/errors";
import colors from "#reusable/colors/colors.json";
import {
    isCategoryNameTaken,
    isCategoryNameValid,
} from "#menu-editor/mobile-friendly/helpers/menu-helpers";
import { useCheckMenuItemHasPromo } from "#menu-editor/mobile-friendly/hooks/use-check-menu-item-has-promo";
import { fetchPromotions } from "#promotion/utils/fetch";
import { useAppDispatch } from "src/redux/hooks";
import { refreshStoreProducts } from "#menu-editor/utils";
import ProductImage from "#menu-editor/mobile-friendly/items/product-image";
import { fetchStoreProducts } from "#menu/api";

import { GlobalDndContext } from "../helpers/dnd-context";
import NewProductModal from "../items/new-product-modal";

import { Table } from "./category-table/table";
import ProductNew from "./product-add-new";
import ProductEdit from "./product-edit";

import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";

import PictureMenu from "./picture-menu/index";
import NewCategoryModal from "./new-category-modal";
import { sortByCategory } from "./helpers";

import { toast } from "sonner";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";

// @ts-ignore
const fileDialog = require("file-dialog");

const DESKTOP_MIN_WIDTH = 992;

/** Default z-index for antd Drawer */
const DRAWER_Z_INDEX = 1000;

type CategoryOptionType = {
    readonly value: string;
    readonly label: string;
};

const MENU_OPTIONS = [
    {
        label: "Regular menu",
        value: "regular",
    },
    {
        label: "Catering menu",
        value: "catering",
    },
];

type Template = {
    label: string;
    value: string;
    product: IProduct;
};

type T = IProductCategoryWithProducts | IProduct | AddonGroup | Addon;
const isProduct = (maybeProduct: T): maybeProduct is IProduct =>
    !!(maybeProduct as IProduct).addonGroups;
const isCategory = (maybeCat: T): maybeCat is IProductCategoryWithProducts =>
    !!(maybeCat as IProductCategoryWithProducts).productIds;
const isAddonGroup = (maybeGroup: T): maybeGroup is AddonGroup =>
    !!(maybeGroup as AddonGroup).addons;
const isAddon = (maybeAddon: T): maybeAddon is Addon =>
    !_.some([
        isProduct(maybeAddon),
        isAddonGroup(maybeAddon),
        isCategory(maybeAddon),
    ]);
const searchData = <Array extends T>(
    dataArray: Array[],
    searchTerm: string,
): Array[] =>
    dataArray.flatMap(<Obj extends T>(obj: Obj): [Obj] | [] => {
        const objHasSearchTerm = Object.entries(obj).some(
            ([key, value]) =>
                (key !== "products" || "addonGroups" || "addons") &&
                String(value).toLowerCase().includes(searchTerm.toLowerCase()),
        );

        if (isAddon(obj)) {
            if (objHasSearchTerm) return [obj];
            else return [];
        }

        if (isCategory(obj)) {
            const matchedProducts = searchData(obj.products ?? [], searchTerm);
            return _.isEmpty(matchedProducts)
                ? objHasSearchTerm
                    ? [obj]
                    : []
                : [
                      {
                          ...obj,
                          products: matchedProducts,
                      },
                  ];
        }

        if (isProduct(obj)) {
            const matchedAddonGroups = searchData(
                obj.addonGroups ?? [],
                searchTerm,
            );
            return _.isEmpty(matchedAddonGroups)
                ? objHasSearchTerm
                    ? [obj]
                    : []
                : // assures found product (even if only addon group is matching) is full product
                  [obj];
        }

        if (isAddonGroup(obj)) {
            const matchedAddons = searchData(obj.addons ?? [], searchTerm);
            return _.isEmpty(matchedAddons)
                ? objHasSearchTerm
                    ? [obj]
                    : []
                : // assures found addon group (even if only addon is matching) is complete
                  [obj];
        }

        // it should never reach here
        return [];
    });

export default function EditMenu() {
    const { setPromotions, productIdToPromotionsMap } =
        useCheckMenuItemHasPromo();
    const dispatch = useAppDispatch();
    const activeStore = useSelector(getActiveStore);
    const thirdPartyEnabled = useSelector(getActiveStoreIs3PIntegrated);
    const activeMenu = useSelector(selectActiveMenu) as string;
    const storeMultiMenus = useSelector(getStoreMultiMenus);
    const multiMenusEnabled = useMultiMenusEnabled();
    const activeProductCategories = useSelector(
        getActiveProductCategories,
    ) as IProductCategoryWithProducts[];
    const products = useSelector(getLegacyProducts) as IProduct[];
    const activeProductCategory = useSelector(getActiveProductCategory);
    const [data, setData] = useState<IProductCategoryWithProducts[]>([]);
    const { width } = useWindowDimensions();
    const [isNew, setIsNew] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [menuAutomation, setMenuAutomation] = useState<MenuAutomation>(
        new MenuAutomation({} as IStore, []),
    );
    const [productInEdit, setProductInEdit] = useState<IProduct | null>(null);
    const [productInDraft, setProductInDraft] =
        useState<Partial<IProduct> | null>(null);
    const [isMenuSyncing, setIsMenuSyncing] = useState<boolean>(false);
    const [importStatus, setImportStatus] = useState({
        status: "none",
        message: "",
    });
    const [categoryOptions, setCategoryOptions] =
        useState<CategoryOptionType[]>();
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [tableData, setTableData] = useState<IProductCategoryWithProducts[]>(
        [],
    );
    const [selectMenu, setSelectMenu] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const _setActiveMenu = fp.compose(dispatch, setActiveMenu);
    const [sortedProducts, setSorted] = useState<IProduct[]>();
    const [modifierGroupsOptions, setModifierGroupsOptions] = useState<
        { label: string; value: string; addonGroup: AddonGroup }[]
    >([]);
    const [isTrackedFieldsModalOpen, setIsTrackedFieldsModalOpen] =
        useState(false);
    const [openNewItemModal, setOpenNewItemModal] = useState<boolean>(false);
    const [productOptions, setProductOptions] = useState<Template[]>([]);
    const [isDuplicateMenuModalOpen, setIsDuplicateMenuModalOpen] =
        useState<boolean>(false);
    const [menuReorderingChanges, setMenuReorderingChanges] =
        useState<number>(0);
    const [showTableSpinner, setShowTableSpinner] = useState(false);
    const [showNewModifierForm, setShowNewModifierForm] =
        useState<boolean>(false);
    const [openNewCategoryModal, setOpenNewCategoryModal] =
        useState<boolean>(false);
    const [newCategory, setNewCategory] = useState(false);
    const incrementMenuOrderChanges = () =>
        !_.isEqual(data, tableData)
            ? setMenuReorderingChanges((prev) => prev + 1)
            : null;

    const isChowlyEnabled =
        activeStore && activeStore.integrations?.chowly?.enabled;

    const fetchPromo = async () => {
        if (!activeStore?._id) {
            return;
        }

        try {
            const fetchedPromotions = await fetchPromotions(activeStore._id);

            if (fetchedPromotions)
                setPromotions(
                    fetchedPromotions.filter((promo) => !promo.isArchived),
                );
        } catch (err) {
            logAndSendError(err);
        }
    };

    useEffect(() => {
        const mapProductToCategories = activeProductCategories.map(
            (category) => {
                const productForRegular: IProduct[] = [];
                category.productIds.map((productId) => {
                    products?.map((product) => {
                        if (product._id === productId) {
                            productForRegular.push(product);
                        }
                    });
                });
                return { ...category, products: productForRegular };
            },
        );
        setData(mapProductToCategories);
    }, [activeStore, activeProductCategories, products]);

    const syncMenuAutomation = useCallback(async () => {
        if (products.length > 0 && activeStore) {
            setIsMenuSyncing(true);
            const tempMenuAutomation = new MenuAutomation(
                activeStore,
                products,
            );
            // check all the issues
            await tempMenuAutomation.checkForIssues();
            // store the automation instance on state
            setMenuAutomation(tempMenuAutomation);
            setIsMenuSyncing(false);
        }
    }, [products, activeStore]);

    const loadOptions = useCallback(async () => {
        if (!activeStore) return;

        // get existing product options
        const productOptions = products.map((product: IProduct) => ({
            label: `${product.category} - ${product.name}`,
            value: product._id,
            product: product,
        }));
        const sortProducts = (first: Template, second: Template) =>
            sortByCategory(
                first.product.category,
                second.product.category,
                activeProductCategory,
            );
        setProductOptions(productOptions.sort(sortProducts));
    }, [activeProductCategory, activeStore?._id, products]);

    useEffect(() => {
        void syncMenuAutomation();
        void loadOptions();

        // TODO: Not sure why this is here
        setSorted(getSortedProducts());
    }, [loadOptions, syncMenuAutomation]);

    const generateCategoryOptions = (data: IProductCategoryWithProducts[]) => {
        const tempCategories: CategoryOptionType[] = [];

        data?.forEach((c) =>
            tempCategories.push({
                label: c.name,
                value: c._id,
            }),
        );

        setCategoryOptions(tempCategories);
    };

    useEffect(() => {
        generateCategoryOptions(data);
    }, [products, activeMenu, data]);

    useEffect(() => {
        if (activeStore?._id) {
            void fetchPromo();
        }
    }, []);

    useEffect(() => {
        setData(activeProductCategories);
    }, [activeProductCategories, products]);

    useEffect(() => {
        setTableData(data);
    }, [data]);

    useEffect(() => {
        const mg = getGroupsList();
        setModifierGroupsOptions(mg);
    }, [sortedProducts]);

    useEffect(() => {
        const storeId = activeStore?._id;
        if (storeId) {
            setIsLoading(true);
            api.stores
                .getImportStatus(storeId)
                .then((value) => {
                    const _value = value.data;
                    setImportStatus({
                        status: _value.status,
                        message: _value.message,
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });

            // TODO: Remove or move elsewhere
            dispatch(fetchInventoryItems(storeId));
        }
    }, [activeStore?._id]);

    // NB: We can use `product.toSorted` once we adopt ES2023
    const getSortedProducts = () =>
        [...products].sort((product1, product2) =>
            compareProducts(product1, product2, activeProductCategory),
        );
    const getGroupsList = () => {
        const ret: {
            label: string;
            value: string;
            addonGroup: AddonGroup;
        }[] = [];

        sortedProducts?.forEach((productNew: IProduct) => {
            productNew.addonGroups.forEach((addonGroup: AddonGroup) => {
                ret.push({
                    label: `${productNew.name} - ${addonGroup.name}`,
                    value: addonGroup._id,
                    addonGroup: {
                        name: addonGroup.name,
                        required: addonGroup.required,
                        limit: addonGroup.limit,
                        supportsMultiple: addonGroup.supportsMultiple,
                        _id: addonGroup?._id,
                        isArchived: addonGroup.isArchived,
                        integrationIds: addonGroup.integrationIds,
                        addons: addonGroup.addons.map((addon) => {
                            const ret = { ...addon };
                            // @ts-expect-error - we are deleting a required field which mutates this shape in a way that violates the type
                            delete ret._id;
                            return ret;
                        }),
                    },
                });
            });
        });
        return ret;
    };

    function compareProducts(
        product1: IProduct,
        product2: IProduct,
        currentProductCategory: string,
    ): number {
        const firstProductCategory = product1.category;
        const secondProductCategory = product2.category;
        // If neither of the products is in the current product category, then there is no change in sort order.
        if (
            firstProductCategory !== currentProductCategory &&
            secondProductCategory !== currentProductCategory
        ) {
            return 0;
        }
        // If both products are in the current product category, then there is no change in sort order.
        else if (
            firstProductCategory === currentProductCategory &&
            secondProductCategory === currentProductCategory
        ) {
            return 0;
        }
        // If product1 is in the active product category, then sort product1 before product2.
        else if (firstProductCategory === currentProductCategory) {
            return -1;
        }
        // At this point in the code, the only remaining case is that
        // product2 is in the active product category and product1 is not.
        // Sort product1 after product2.
        else {
            return 1;
        }
    }

    const processMenuTypeSelection = (selectedOption: {
        label: string;
        value: ActiveMenu;
    }) => {
        if (!selectedOption || !activeStore) {
            return;
        }
        const activeMenu = selectedOption.value;
        _setActiveMenu(activeMenu);
    };

    useEffect(() => {
        if (importStatus.status === "none") {
            return;
        }
        if (importStatus.status === "success") {
            toast.success(
                `Congratulations! Menu has been imported successfully.`,
            );
        } else if (importStatus.status === "import") {
            toast.info(`Menu is importing: ${importStatus.message}`);
        } else {
            toast.error(`Oops. Menu import failed: ${importStatus.message}`);
        }
        setImportStatus({
            status: "none",
            message: "",
        });
    }, [importStatus.message, importStatus.status]);

    type ItemCardCardProps = {
        depth: number;
        product: IProduct | IProductCategoryWithProducts;
        setProductInEdit: (value: IProduct) => void;
    };

    const ItemCard: React.FC<ItemCardCardProps> = ({
        depth,
        product,
        setProductInEdit,
    }) => {
        const isCatering = activeMenu === "catering";
        const errorMsg = isCatering
            ? "Catering product category name must end with '(Catering)'"
            : "Non-catering product category name cannot end with '(Catering)'";
        const [name, setName] = useState<string>(product.name);
        const [image, setImage] = useState<string | null | undefined>(
            isProduct(product) ? product.image : undefined,
        );
        const dispatch = useDispatch();

        const upload = (imageURL: string) => {
            if (activeStore) {
                api.products
                    .update(product?._id, {
                        image: imageURL,
                    })
                    .then(async (res) => {
                        dispatch(updateLegacyProduct(res.data.product));
                        return api.stores.getOne(activeStore._id);
                    })
                    .then((res) => {
                        dispatch(setActiveStore(res.data.store));
                    })
                    .catch((err) => {
                        sendError(err);
                        toast.error("could not upload photo");
                    });
            }
        };

        const updateCategoryOfProducts = async (newCategoryName: string) => {
            if (!activeStore) return;

            try {
                await api.stores.updateCategory(activeStore._id, product._id, {
                    name: newCategoryName,
                });

                toast.success("Saved Category Name");

                const { products } = await fetchStoreProducts(activeStore._id);
                const storeRes = await api.stores.getOne(activeStore._id);
                dispatch(setActiveStore(storeRes.data.store));
                dispatch(setLegacyProducts(products));
                dispatch(setGlobalIsLoading(false));
            } catch (err) {
                sendError(err);
                toast.error("Failed to update category name");
            }
        };

        const handlePressSubmit = (newCategoryName: string) => {
            if (newCategoryName === product.name) return;
            if (!newCategoryName) {
                toast.error("Category name can not be empty");
                setName(product.name);
                return;
            }
            if (isCategoryNameTaken(newCategoryName, products)) {
                setName(product.name);
                toast.error("This category name is already taken");
                return;
            }
            const categoryNameValid = isCategoryNameValid(
                newCategoryName,
                isCatering,
            );
            if (!categoryNameValid) {
                toast.error(errorMsg);
                setName(product.name);
                return;
            }
            void updateCategoryOfProducts(newCategoryName);
        };

        return (
            <td css={CardStyle}>
                {depth == 0 ? null : (
                    <span className="item-img">
                        <ProductImage
                            imageURL={image}
                            setImage={setImage}
                            showImageUploadOnly
                            updateProduct={upload}
                            disableEdit={thirdPartyEnabled}
                        />
                    </span>
                )}

                <span
                    className={
                        depth == 0
                            ? "item-name"
                            : width >= DESKTOP_MIN_WIDTH || isDesktop
                              ? "sub-row-item-name"
                              : "sub-row-item-name-mobile"
                    }
                    onClick={() => {
                        if (!isProduct(product)) return;
                        if (depth === 1) {
                            setProductInEdit(product);
                            openProductEditSlideOver();
                        }
                    }}
                >
                    {depth === 0 ? (
                        <>
                            <Tooltip
                                title="Tap to change category name"
                                placement="topLeft"
                            >
                                <TextArea
                                    size="small"
                                    placeholder="Category Name"
                                    className="category-name"
                                    style={{
                                        width: "80%",
                                        minHeight: 20,
                                        maxHeight: 200,
                                    }}
                                    autoSize
                                    value={name}
                                    bordered={false}
                                    onChange={(
                                        e: React.ChangeEvent<HTMLTextAreaElement>,
                                    ) => setName(e.target.value)}
                                    onBlur={(
                                        e: React.FocusEvent<HTMLTextAreaElement>,
                                    ) => {
                                        handlePressSubmit(e.target.value);
                                    }}
                                    onKeyDown={(
                                        e: React.KeyboardEvent<HTMLTextAreaElement>,
                                    ) => {
                                        if (e.key === "Enter") {
                                            e.currentTarget.blur();
                                        }
                                    }}
                                />{" "}
                            </Tooltip>
                        </>
                    ) : (
                        <>{name}</>
                    )}
                </span>
                {depth === 0 && isCategory(product) ? (
                    <span className="total-products">
                        {product.products
                            ? `${product.products.length} items`
                            : ""}
                    </span>
                ) : isProduct(product) ? (
                    <span
                        className="item-price"
                        onClick={() => {
                            setProductInEdit(product);
                            openProductEditSlideOver();
                        }}
                    >
                        {!product.priceByWeight
                            ? `$${toLocaleFixed(product.price)}`
                            : `$${toLocaleFixed(
                                  product.priceByWeight?.perUnit,
                              )}/${product.priceByWeight?.unit} `}
                        {product.soldOut ? (
                            <span className="sold-out">SOLD OUT</span>
                        ) : null}
                    </span>
                ) : null}
            </td>
        );
    };

    const SelectMenuType = (
        <span className="select-menu">
            <DropDownSelect
                placeholder={"Select Menu"}
                options={MENU_OPTIONS}
                value={MENU_OPTIONS.find(
                    (menuOption) => menuOption.value === activeMenu,
                )}
                onChange={(selectedOption: {
                    label: string;
                    value: ActiveMenu;
                }) => {
                    setSelectMenu(!selectMenu);
                    processMenuTypeSelection(selectedOption);
                }}
                fontSize={width > Constants.MOBILE_MAX_WIDTH ? "16px" : "14px"}
                fontWeight={width > Constants.MOBILE_MAX_WIDTH ? "500" : "400"}
                isMobile={width <= Constants.MOBILE_MAX_WIDTH || isMobile}
            />
        </span>
    );

    const closeNewProductSlideOver = () => {
        setIsNew(false);
        setProductInDraft(null);
        setShowNewModifierForm(false);
        setNewCategory(false);
    };

    const openNewProductSlideOver = () => {
        setIsNew(true);
    };

    const shouldOpenTrackedFieldsModal = () =>
        !!productInEdit &&
        storeMultiMenus.some(
            ({ menuOverrides }) =>
                !!menuOverrides?.products?.[productInEdit._id] ||
                productInEdit.addonGroups
                    .flatMap(({ addons }) => addons.map(({ _id }) => _id))
                    .some((addonId) => !!menuOverrides?.addons?.[addonId]),
        );

    const closeProductEditSlideOver = () => {
        setIsEdit(false);
        if (shouldOpenTrackedFieldsModal()) setIsTrackedFieldsModalOpen(true);
        else {
            setProductInEdit(null);
            setShowNewModifierForm(false);
        }
    };

    useEffect(() => {
        if (!isTrackedFieldsModalOpen) {
            setProductInEdit(null);
            setShowNewModifierForm(false);
        }
    }, [isTrackedFieldsModalOpen]);

    const openProductEditSlideOver = () => {
        setIsEdit(true);
    };

    const columns: ColumnDef<IProductCategoryWithProducts | IProduct>[] =
        React.useMemo(
            () => [
                {
                    id: "_id",
                    Cell: (props: CellInfo) => (
                        <ItemCard
                            product={props.row.original}
                            setProductInEdit={setProductInEdit}
                            depth={props.row.depth}
                        />
                    ),
                },
            ],
            [data, tableData, products, activeProductCategories],
        );
    const TotalItems = () => (
        <span className="total-items">
            <span>
                {activeProductCategories
                    ? activeProductCategories.length + " "
                    : 0}
                Items
            </span>
        </span>
    );

    const actionMenu = (
        <Menu>
            <Menu.Item
                key="2"
                disabled={
                    !fp.getOr("", "_id", activeStore) || products.length > 0
                }
                onClick={() => setIsDuplicateMenuModalOpen(true)}
            >
                Duplicate Menu
            </Menu.Item>
        </Menu>
    );

    const newItemMenu = (
        <Menu>
            <Menu.Item key="1" onClick={() => openNewProductSlideOver()}>
                New Item
            </Menu.Item>

            <Menu.Item
                key="3"
                onClick={() => {
                    setOpenNewItemModal(true);
                }}
            >
                New Item from Template
            </Menu.Item>
            <Menu.Item
                key="4"
                onClick={() => {
                    setOpenNewCategoryModal(true);
                }}
            >
                New Category
            </Menu.Item>
        </Menu>
    );

    const mobileActionMenu = (
        <Menu>
            <Menu.Item
                key="2"
                disabled={
                    !fp.getOr("", "_id", activeStore) || products.length > 0
                }
                onClick={() => setIsDuplicateMenuModalOpen(true)}
            >
                Duplicate Menu
            </Menu.Item>
            <Menu.Item key="4" onClick={() => openNewProductSlideOver()}>
                New Item
            </Menu.Item>

            <Menu.Item
                key="6"
                onClick={() => {
                    setOpenNewItemModal(true);
                }}
            >
                New Item from Template
            </Menu.Item>
            <Menu.Item
                key="7"
                onClick={() => {
                    setOpenNewCategoryModal(true);
                }}
            >
                New Category
            </Menu.Item>
        </Menu>
    );

    const Actions = () =>
        width > Constants.MOBILE_MAX_WIDTH ? (
            <p className="mb-4" css={actionStyles}>
                {SelectMenuType}
                <span className="space"></span>
                <Dropdown
                    overlay={actionMenu}
                    placement="bottomRight"
                    className="action-btn"
                    trigger={["click"]}
                    overlayStyle={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                    }}
                >
                    <Button size="large">
                        <Space>
                            Action
                            <ChevronDown className="action-btn-icon-fill action-btn-icon " />
                        </Space>
                    </Button>
                </Dropdown>
                <Dropdown
                    overlay={newItemMenu}
                    trigger={["click"]}
                    overlayStyle={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                    }}
                    className="action-btn"
                >
                    <Button size="large" type="primary">
                        <Space>
                            <span onClick={() => openNewProductSlideOver()}>
                                New Item |
                            </span>
                            <ChevronDown className="action-btn-icon-white action-btn-icon" />
                        </Space>
                    </Button>
                </Dropdown>
            </p>
        ) : (
            <p className="newItemWrapper mb-4">
                <Dropdown
                    overlay={mobileActionMenu}
                    placement="bottomRight"
                    trigger={["click"]}
                    className="action-btn"
                    overlayStyle={{
                        fontFamily: "Inter",
                        fontSize: "16px",
                    }}
                >
                    <Button shape="circle" icon={<DashOutlined />} />
                </Dropdown>
            </p>
        );

    const handleCategoryReorder = async () => {
        setShowTableSpinner(true);
        // need to remove products field only keep productId field
        const updatedRecords: IProductCategoryWithProducts[] = tableData.map(
            ({
                name,
                productIds,
                products,
                _id,
                isArchived,
            }: {
                name: string;
                productIds: string[];
                products: IProduct[];
                _id: string;
                isArchived?: Date;
            }) => ({
                name,
                productIds,
                products,
                _id,
                isArchived,
            }),
        );

        let updates: Partial<IStore> = {
            productCategories: updatedRecords,
        };

        if (activeStore && activeMenu === "catering") {
            updates = {
                catering: {
                    ...activeStore.catering,
                    productCategories: updatedRecords,
                },
            };
        }

        activeStore &&
            api.stores
                .update(activeStore._id, updates)
                .then((response) => {
                    dispatch(setActiveStore(response.data.store));
                })
                .catch(async (err) => {
                    toast.error("Error could not update the categories");
                    sendError(err);
                    // menu reordering failed, fetch the correct order and render it before user reordering again
                    await api.stores
                        .getOne(activeStore._id)
                        .then((response) => {
                            dispatch(setActiveStore(response.data.store));
                        })
                        .catch((err) => {
                            sendError(err);
                        });
                })
                .finally(async () => {
                    setMenuReorderingChanges(0);
                    setShowTableSpinner(false);
                });
    };

    useEffect(() => {
        if (!globalFilter) {
            setTableData(data);
        }
        const timeout = setTimeout(
            () => setTableData(searchData(data, globalFilter)),
            500,
        );
        return () => {
            clearTimeout(timeout);
        };
    }, [data, globalFilter]);

    return (
        <div css={BodyStyle}>
            {isLoading ? (
                <div className="spinnerWrapper">
                    <Spin tip="Loading..." />
                </div>
            ) : (
                <GlobalDndContext>
                    <MenuTopLevelContext.Provider
                        value={{
                            openProductEditSlideOver: openProductEditSlideOver,
                            closeProductEditSlideOver:
                                closeProductEditSlideOver,
                            openNewProductSlideOver: openNewProductSlideOver,
                            closeNewProductSlideOver: closeNewProductSlideOver,
                            storeHours: activeStore && activeStore.hours,
                            categoryOptions: categoryOptions,
                            modifierGroupsOptions: modifierGroupsOptions,
                            productInDraft: productInDraft,
                            setProductInDraft: setProductInDraft,
                            productInEdit: productInEdit,
                            setProductInEdit: setProductInEdit,
                            setIsLoading: setIsLoading,
                            isLoading: isLoading,
                            setMenuReorderingChanges: setMenuReorderingChanges,
                            setShowTableSpinner: setShowTableSpinner,
                            showNewModifierForm: showNewModifierForm,
                            setShowNewModifierForm: setShowNewModifierForm,
                            data: tableData,
                            setData: setTableData,
                            handleCategoryReorder: handleCategoryReorder,
                            showTableSpinner: showTableSpinner,
                            incrementMenuOrderChanges:
                                incrementMenuOrderChanges,
                            activeStoreId: fp.getOr("", "_id", activeStore),
                            isNew: isNew,
                            products: products,
                        }}
                    >
                        <div>
                            {thirdPartyEnabled && (
                                <Alert variant="warning">
                                    <AlertTitle className="text-base">
                                        <ExclamationTriangleIcon className="mr-2" />
                                        {isChowlyEnabled
                                            ? "Chowly"
                                            : "Checkmate"}{" "}
                                        Integrated Store
                                    </AlertTitle>
                                    <AlertDescription className="text-sm">
                                        Unable to edit menu. Menu is synced from
                                        a third party.
                                    </AlertDescription>
                                </Alert>
                            )}
                        </div>
                        <TotalItems />
                        <Table
                            columns={columns}
                            isMenuSyncing={isMenuSyncing}
                            menuAutomation={menuAutomation}
                            selectMenu={selectMenu}
                            SelectMenuType={SelectMenuType}
                            globalFilter={globalFilter}
                            onChange={setGlobalFilter}
                        >
                            {!thirdPartyEnabled && <Actions />}
                        </Table>
                        <Drawer
                            width={
                                width < Constants.MOBILE_MAX_WIDTH
                                    ? width
                                    : width / 2
                            }
                            placement="right"
                            onClose={closeNewProductSlideOver}
                            open={isNew}
                            closable={false}
                            destroyOnClose={true}
                            push={false}
                            forceRender={true}
                        >
                            <ProductNew
                                menuAutomation={menuAutomation}
                                newCategory={newCategory}
                            />
                        </Drawer>
                        <Drawer
                            width={
                                width < Constants.MOBILE_MAX_WIDTH
                                    ? width
                                    : width / 2
                            }
                            placement="right"
                            onClose={closeProductEditSlideOver}
                            open={isEdit}
                            closable={false}
                            push={false}
                            destroyOnClose={true}
                            forceRender={true}
                            zIndex={DRAWER_Z_INDEX}
                        >
                            <ProductEdit
                                parentDrawerZIndex={DRAWER_Z_INDEX}
                                menuAutomation={menuAutomation}
                                productIdToPromotionsMap={
                                    productIdToPromotionsMap
                                }
                            />
                        </Drawer>
                        {multiMenusEnabled && (
                            <TrackProductFieldsModal
                                isModalOpen={isTrackedFieldsModalOpen}
                                productInEdit={productInEdit}
                                setIsModalOpen={setIsTrackedFieldsModalOpen}
                            />
                        )}
                        <NewProductModal
                            openNewItemModal={openNewItemModal}
                            setOpenNewItemModal={setOpenNewItemModal}
                            productOptions={productOptions}
                            categoryOptions={categoryOptions}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            openNewProductSlideOver={openNewProductSlideOver}
                            setProductInDraft={setProductInDraft}
                        />
                        <NewCategoryModal
                            newCategory={newCategory}
                            setNewCategory={setNewCategory}
                            openNewCategoryModal={openNewCategoryModal}
                            setOpenNewCategoryModal={setOpenNewCategoryModal}
                            openNewProductSlideOver={openNewProductSlideOver}
                            setProductInDraft={setProductInDraft}
                            products={products}
                        />
                        <DuplicateMenu
                            isDuplicateMenuModalOpen={isDuplicateMenuModalOpen}
                            setIsDuplicateMenuModalOpen={
                                setIsDuplicateMenuModalOpen
                            }
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        ></DuplicateMenu>
                        <PictureMenu
                            onShow={openProductEditSlideOver}
                            setProductInEdit={setProductInEdit}
                        />
                    </MenuTopLevelContext.Provider>
                </GlobalDndContext>
            )}

            {menuReorderingChanges > 0 ? (
                <div className="menu-changes-action-wrapper">
                    <div className="menu-changes-info">
                        <span className="primary">Menu order updated</span>
                        <span className="secondary">
                            {`${menuReorderingChanges} changes`}
                        </span>
                    </div>
                    <div className="menu-changes-actions">
                        <Button
                            size="large"
                            className="menu-cancel"
                            onClick={async () => {
                                setShowTableSpinner(true);

                                activeStore &&
                                    (await refreshStoreProducts(
                                        dispatch,
                                        activeStore._id,
                                    )
                                        .then(() => {
                                            setMenuReorderingChanges(0);
                                            setShowTableSpinner(false);
                                        })
                                        .catch(
                                            compose(
                                                () =>
                                                    setShowTableSpinner(false),
                                                alertAxiosError,
                                            ),
                                        )
                                        .finally(() => {
                                            setMenuReorderingChanges(0);
                                            setShowTableSpinner(false);
                                        }));
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            className="menu-save"
                            onClick={async () => handleCategoryReorder()}
                        >
                            Save Changes
                        </Button>
                    </div>
                </div>
            ) : null}
        </div>
    );
}

const actionStyles = css`
    display: flex;
    flex-direction: row;
    height: 2rem;

    .space {
        flex: 1;
        min-width: 1rem;
        flex-shrink: 0.1;
    }

    .select-menu {
        margin-left: 0.5rem;
        max-width: 12rem;
        height: 10pxs !important;
        flex: 4;
    }
`;

const CardStyle = css`
    display: grid;
    justify-content: center;
    grid-template-columns: auto repeat(6, minmax(1.3rem, 1fr)) auto auto auto auto;
    grid-auto-rows: minmax(1.5rem, auto);
    align-items: center;

    .item-name {
        grid-column: 1/11;
        grid-row: 1/2;
        padding-left: 2rem;
        padding-top: 1.5rem;
        font-size: 18px !important;
        font-family: "Inter";
    }

    .sub-row-item-name {
        grid-column: 2/8;
        grid-row: 1/2;
        padding-left: 1.4rem;
        grid-auto-rows: minmax(2rem, auto);
        line-height: 1.2rem;
        align-self: center;
        cursor: pointer;
        font-family: "Inter";
        font-size: 16px;
    }

    .sub-row-item-name-mobile {
        grid-column: 2/11;
        grid-row: 1/2;
        padding-left: 1.4rem;
        grid-auto-rows: minmax(2rem, auto);
        align-self: start;
        line-height: 1.2rem;
        padding-bottom: 0.5rem;
    }

    .total-products {
        grid-column: 1/11;
        grid-row: 2;
        padding-left: 2rem;
        align-self: start;
        padding-top: 1rem;
        padding-bottom: 1.5rem;
    }

    .item-price {
        grid-column: 2/11;
        grid-row: 2/3;
        padding-left: 1.4rem;
        padding-bottom: 5px;
        align-self: start;
        cursor: pointer;
        font-family: "Inter";
        font-size: 16px;
    }

    .sold-out {
        margin-left: 0.5rem;
        background-color: rgba(241, 245, 248, 1);
        color: rgba(96, 108, 118, 1);
        border-radius: 6px;
        padding: 0 5px;
        font-family: "Inter";
        font-weight: 800;
        font-size: 12px;
    }

    .item-img {
        grid-column: 1/2;
        grid-row: 1/3;
        margin-left: 4px;
        padding-top: 0;
        padding-left: 1rem;
        align-self: center;
        margin-top: 5px;
        margin-bottom: 10px;
        margin-left: 1.5rem;
        justify-self: start;
        position: relative;
    }
`;

const BodyStyle = css`
    ${styledWrapperCSS};
    margin-bottom: 5rem;
    .bannerWrapper {
        margin-top: 20px;
    }
    .banner {
        margin-top: 5px;
        border-left: 6px solid ${SystemColors.v1.candy80};
        border-radius: 8px;
    }
    .banner.error {
        margin-top: 25px;
        color: ${SystemColors.v1.melon50};
        border-left: 6px solid ${SystemColors.v1.melon50};
        border-radius: 8px;
    }
    .banner.warning {
        margin-top: 25px;
        color: ${SystemColors.v1.macaroni40};
        border-left: 6px solid ${SystemColors.v1.macaroni40};
        border-radius: 8px;
    }

    .spinnerWrapper {
        margin-top: 50%;
        margin-bottom: 50%;
        text-align: center;
    }

    .category-name {
        height: 20px;
        padding-left: 1px;
        font-weight: 600;
        font-family: Inter;
        font-size: 18px;
        line-height: 18px;
        padding-top: 0;
        padding-bottom: 0;
        position: relative;
    }

    .total-items {
        padding-left: 1.2rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        display: flex;
        margin-top: 20px;
        line-height: 32px;

        @media ${ScreenState.MOBILE} {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr auto 1fr 1fr 1fr 1fr 1fr 1fr;
            grid-auto-rows: 1;
            span {
                grid-column: 6;
            }
        }
    }

    .newItemWrapper {
        display: flex;
        margin-top: 4px;
    }

    .newItemWrapper:hover {
        cursor: pointer;
    }

    .newItemWrapper > .ant-btn.ant-dropdown-trigger {
        border-radius: 48%;
        width: 32px;
        height: 32px;
    }
    .action-btn {
        border-radius: 18px;
        font-family: "Inter";
        font-size: 16px;
        margin-right: 0.5rem;
        font-style: bold;
    }

    .action-btn-icon {
        width: 0.7rem;
        height: 0.7rem;
    }

    .action-btn-icon-fill path {
        fill: rgb(204, 204, 204);
    }

    .action-btn-icon-white path {
        fill: white;
    }

    .menu-changes-action-wrapper {
        background-color: ${SystemColors.v2.salt10.light};
        border-top: 1px solid ${colors["neutral-400"]};
        border-bottom: 1px solid ${colors["neutral-400"]};
        position: sticky;
        position: -webkit-sticky;
        bottom: 0%;
        right: 0px;
        left: 0px;
        margin: -16px;
        padding: 12px;
        display: flex;
        align-items: center;
        @media ${ScreenState.MOBILE} {
            bottom: 0%;
        }
    }

    .menu-changes-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 1%;
    }

    .primary {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }

    .menu-changes-actions {
        display: flex;
        flex: 1;
        gap: 5%;
        justify-content: flex-end;
        margin-right: 8%;
        @media ${ScreenState.MOBILE} {
            margin-right: 1%;
        }
    }

    .menu-save {
        border-radius: 8px;
    }

    .menu-cancel {
        border-radius: 8px;
    }
`;
