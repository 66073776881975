import React, { ReactElement } from "react";
import { SystemColors } from "@snackpass/design-system";
import styled from "@emotion/styled";
import { ScreenState } from "@snackpass/snackpass-types";
import { Tooltip } from "antd";

import { usePromotionForm } from "#promotion/utils/edit";
import { PromoShortcut } from "#promotion/utils/types";

type Props = {
    icon: ReactElement;
    title: string;
    description: string;
    enabled?: boolean;
    disabledText?: string;
    onClick?: () => void;
    type: PromoShortcut;
};

type CardProps = {
    enabled?: boolean;
};

const TooltipText = ({ disabledText }: { disabledText: string }) => (
    <TooltipContainer>
        <DisabledText>This shortcut is coming soon!</DisabledText>
        <DisabledText>{disabledText}</DisabledText>
    </TooltipContainer>
);

export const ShortcutCard = ({
    icon,
    title,
    description,
    enabled,
    disabledText,
    onClick,
    type,
}: Props) => {
    const { editPromotionFormFromShortcut } = usePromotionForm();

    const _onClick = () => {
        // Keep track of shortcut click, runs regardless of enabled status
        onClick && onClick();
        // Go to shortcut form
        enabled && editPromotionFormFromShortcut(type);
    };

    return enabled ? (
        <CardContainer onClick={_onClick} enabled={enabled}>
            <CartContentContainer>
                {icon}
                <CardTextContainer>
                    <CardTitle>{title}</CardTitle>
                    <CardDescription>{description}</CardDescription>
                </CardTextContainer>
            </CartContentContainer>
        </CardContainer>
    ) : (
        <Tooltip
            title={<TooltipText disabledText={disabledText || ""} />}
            trigger={"click"}
            color={SystemColors.v1.gray20}
            overlayInnerStyle={{ borderRadius: 8 }}
        >
            <CardContainer onClick={_onClick}>
                <CartContentContainer>
                    {icon}
                    <CardTextContainer>
                        <CardTitle>{title}</CardTitle>
                        <CardDescription>{description}</CardDescription>
                    </CardTextContainer>
                </CartContentContainer>
            </CardContainer>
        </Tooltip>
    );
};

const CardContainer = styled.button<CardProps>`
    max-width: 324px;
    height: 168px;
    padding: 12px;
    border: 1px solid ${SystemColors.v1.gray80};
    border-radius: 16px;
    text-align: left;
    background-color: transparent;
    box-shadow: none;

    &:hover {
        cursor: pointer;
        border: 1px solid ${SystemColors.v1.candy70};
    }

    @media ${ScreenState.MOBILE} {
        width: 100%;
        height: auto;
        padding: 24px;
        max-width: none;
    }
`;

const CartContentContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${ScreenState.MOBILE} {
        flex-direction: row;
    }
`;

const CardTextContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media ${ScreenState.MOBILE} {
        padding-left: 16px;
    }
`;

const CardTitle = styled.p`
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 4px;

    @media ${ScreenState.MOBILE} {
        margin-top: 5px;
    }
`;

const CardDescription = styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: ${SystemColors.v1.gray30};
    margin: 0;
`;

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`;
const DisabledText = styled.p`
    margin: 0;
    text-align: center;
`;
